<template>
  <div :class="rootClasses">
    <div v-if="label" class="hb-form-group__label">
      {{ label }}
      <span v-if="info" v-tooltip="info">
        <nuxt-icon name="info" filled />
      </span>
      <div v-if="sub" class="hb-form-group__sub">{{ sub }}</div>
    </div>
    <div class="hb-form-group__row">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'

export default {
  name: 'HbFormGroup',
  props: {
    label: { type: String, default: () => null },
    sub: { type: String, default: () => null },
    info: { type: String, default: () => null },
    hasBorder: { type: Boolean, default: () => true },
    hasTopBorder: { type: Boolean, default: () => true },
    noDistance: { type: Boolean, default: () => false },
    noMargin: { type: Boolean, default: () => false },
    noPadding: { type: Boolean, default: () => false },
  },
  setup(props) {
    const rootClasses = computed(() => ({
      'hb-form-group': true,
      'hb-form-group--has-border': props.hasBorder,
      'hb-form-group--has-top-border': props.hasTopBorder,
      'hb-form-group--no-distance': props.noDistance,
      'hb-form-group--no-margin': props.noMargin,
      'hb-form-group--no-padding': props.noPadding,
    }))

    return {
      rootClasses,
    }
  },
}
</script>

<style lang="scss">
.hb-form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px;
  margin: 0 -49px;
  gap: 20px;

  &__label {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.2px;
    flex: 0 0 140px;
  }

  &__sub {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    opacity: 0.5;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }

  &--has-border {
    border-bottom: 1px solid var(--hb-gray2);
  }

  &--has-top-border {
    border-top: 1px solid var(--hb-gray2);
  }

  &--no-distance {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &--no-margin {
    margin: 0 !important;
  }

  &--no-padding {
    padding: 0 !important;
  }

  @include mobile {
    padding: 15px 25px;
    margin: 0 -25px;
    flex-direction: column;
    gap: 11px;
    align-items: flex-start;

    &__label {
      flex: auto;
    }

    &__row {
      flex-direction: column;
      gap: 15px;
    }
  }
}
</style>
